import {
  Navbar,
  Text,
  Container,
  Card,
  Spacer,
  Col,
  Row,
  Button,
  Image,
  Grid,
  Link,
  NextUIProvider
} from '@nextui-org/react';
import { Send } from 'react-iconly'
import { createTheme } from "@nextui-org/react"

const header = new URL('../public/images/hubble.jpg', import.meta.url);
const schemacan = new URL('../public/images/schemacan-code.png', import.meta.url);

var darkTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {
      primary: '#a63d40',
      primaryBorder: '#a63d40',
      primaryBorderHover: '#a63d40',
      primarySolidHover: '#a63d40',
      primarySolidContrast: '#ffffff',
      primaryShadow: '#111111',

      secondary: '#eec643',
      secondaryBorder: '#eec643',
      secondaryBorderHover: '#eec643',
      secondarySolidHover: '#eec643',
      psecondarySolidContrast: '#ffffff',
      secondaryShadow: '#111111',

      link: '#eec643',
    },
  }
});

export default function App() {
  return (
    <NextUIProvider theme={darkTheme}>
      <Navbar isBordered variant="sticky" maxWidth="fluid">
        <Navbar.Brand>
          <Text b size={24}>AutonoMoose.</Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs" variant="underline-rounded">
          <Navbar.Link href="#/about"><Text b>About</Text></Navbar.Link>
          <Navbar.Link href="#/blog"><Text b>Blog</Text></Navbar.Link>
          <Navbar.Link href="#/contact"><Text b>Contact</Text></Navbar.Link>
        </Navbar.Content>
      </Navbar>
      <Spacer y={4}/>
      <Container>
        <Card variant="bordered" isPressable>
          <Card.Header css={{ position: "absolute", zIndex: 1, top: 0, left: 5 }}>
            <Col>
              <Text h1 size={12} weight="bold" transform="uppercase" color="#ffffffAA">
                Find out about how
              </Text>
              <Text h1 size={28} color="white" css={{ lineHeight: 1}}>
                We are changing firmware development
              </Text>
            </Col>
          </Card.Header>
          <Card.Image
            src={header}
            objectFit="cover"
            width="100%"
            height={320}
          />
          <Card.Body css={{ position: "absolute", zIndex: 1, bottom: 0, right: 10 }}>
            <Row justify="right">
              <Text transform="uppercase" b>Read more</Text>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <Spacer y={4}/>
      <Container>
        <Grid.Container gap={2} justify="center">
          <Grid xs>
            <Container>
              <Text h2>SchemaCAN: An Idomatic Replacement for DBC</Text>
              <Text size={20}>Human readable declarations of CAN Bus messages for interoperability between systems and codebases.</Text>
              <Spacer y={1}/>
              <Button size="md" auto><Text transform="uppercase" b>Read the spec</Text></Button>
            </Container>
          </Grid>
          <Grid css={{ maxWidth: "400px" }}>
            <Card variant="bordered">
              <Image
                src={schemacan}
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </Card>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={4}/>
      <Container>
        <Grid.Container gap={2} justify="center">
          <Grid css={{ maxWidth: "400px" }}>
            <Card variant="bordered">
              <Image
                src={schemacan}
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </Card>
          </Grid>
          <Grid xs>
            <Container>
              <Text h2>wkbnch: Programatic interface definitions in your language of choice </Text>
              <Text size={20}>Human readable declarations of CAN Bus messages for interoperability between systems and codebases.</Text>
              <Spacer y={1}/>
              <Button size="md" auto><Text transform="uppercase" b>Try now</Text></Button>
            </Container>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={6}/>
      <footer>
        <Container fluid css={{ borderTop: "1px solid #FFFFFF11", p: 20 }}>
          <Grid.Container gap={2} justify="center">
            <Grid xs>
              <Container>
                <Text size={32} b>Open Quality Tools.</Text>
              </Container>
            </Grid>
            <Grid xs={2}>
              <Container>
                <Text b>Resources</Text>
                <Spacer y={1}/>
                <Text size={20}><Link>GitHub</Link></Text>
                <Text size={20}><Link>Twitter</Link></Text>
              </Container>
            </Grid>
            <Grid xs={2}>
              <Container>
                <Text b>Company</Text>
                <Spacer y={1}/>
                <Text size={20}><Link href="#/about">About</Link></Text>
                <Text size={20}><Link href="#/contact">Contact</Link></Text>
              </Container>
            </Grid>
          </Grid.Container>
        </Container>
      </footer>
    </NextUIProvider>
  );
}
